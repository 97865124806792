import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Alert.module.less";
import VXButton from "../SimpleElements/VXButton/VXButton";
import { COLOR_TYPE_CALL_TO_ACTION, COLOR_TYPE_TRANSPARENT, COLOR_BLACK, COLOR_TYPE_DISABLED }  from '../SimpleElements/VXButton/ButtonHelper';
import { VXButtonLineConfig } from '../SimpleElements/VXButton/VXButtonConfig';
import VXButtonOutline from "../SimpleElements/VXButton/Presets/VXButtonOutline";
import STYLES from "../SimpleElements/VXButton/EnumVXButton";

const TYPE = {
    WARNING: 'warning',
    INFO:    'info',
    SUCCESS: 'success',
    ERROR:   'error',
};

const THEME = {
    DARK:  'dark',
    LIGHT: 'light',
};

const POSITION = {
    FIXED:    'fixed',
    RELATIVE: 'relative',
};

const BUTTON = {
    PRIMARY:   'primary',
    SECONDARY: 'secondary',
    OUTLINE:   'outline',
};

const getIconClasses = (type) => {
    const iconClasses = [styles.alertIcon, "icon"];

    switch (type) {
        case TYPE.WARNING:
            iconClasses.push(styles.alertIconWarning);
            iconClasses.push("-icon-round-warning-full");
            break;
        case TYPE.SUCCESS:
            iconClasses.push(styles.alertIconSuccess);
            iconClasses.push("-icon-round-success-full");
            break;
        case TYPE.ERROR:
            iconClasses.push(styles.alertIconError);
            iconClasses.push("-icon-round-close");
            break;
        default:
        case TYPE.INFO:
            iconClasses.push("-icon-round-info-full");
            break;
    }
    return iconClasses.join(" ");
};

const getWrapperClasses = ({theme, position, type}) => {
    const wrapperClasses = [styles.alertWrapper];
    theme === THEME.LIGHT && wrapperClasses.push(styles.alertWrapperThemeLight);
    position === POSITION.FIXED && wrapperClasses.push(styles.alertWrapperPositionFixed);

    switch (type) {
        case TYPE.WARNING:
            wrapperClasses.push(styles.alertWrapperWarning);
            break;
        case TYPE.SUCCESS:
            wrapperClasses.push(styles.alertWrapperSuccess);
            break;
        case TYPE.ERROR:
            wrapperClasses.push(styles.alertWrapperError);
            break;
        default:
        case TYPE.INFO:
            wrapperClasses.push(styles.alertWrapperInfo);
            break;
    }
    return wrapperClasses.join(" ");
};


const getButton = (button, theme) => {
    let buttonColor = COLOR_TYPE_CALL_TO_ACTION;
    switch (button.type) {
        case BUTTON.OUTLINE:
            buttonColor = theme === THEME.LIGHT ? COLOR_BLACK : COLOR_TYPE_TRANSPARENT;
            return <VXButtonOutline color={buttonColor} staticMode={true} line1={[new VXButtonLineConfig(button.text)]} onClick={button.onClick} />;
        case BUTTON.SECONDARY:
            buttonColor = theme === THEME.LIGHT ? COLOR_BLACK : COLOR_TYPE_TRANSPARENT;
            return <VXButton color={buttonColor} staticMode={true} line1={[new VXButtonLineConfig(button.text)]} onClick={button.onClick} border={STYLES.BORDER.NONE} />;
        default:
        case BUTTON.PRIMARY:
            return <VXButton color={buttonColor} staticMode={true} line1={[new VXButtonLineConfig(button.text)]} onClick={button.onClick} />;
    }
};

const Alert = (props) => {
    const {
        type,
        theme,
        headline,
        text,
        onClose,
        showIcon,
        buttons,
        inputField,
        textButton
    } = props;

    useEffect(() => {
        let timerId;
        if (props.useTimer && typeof onClose === 'function') {
            timerId = setTimeout(() => {
                    onClose();
            }, props.timer);
        }
        return () => clearTimeout(timerId); // Cleanup
    }, [props.useTimer, props.timer, onClose]);

    const headlineElement = headline && <div className={styles.alertHeadline} dangerouslySetInnerHTML={{
        __html: headline, sanitize: true,
    }} />;

    const textElement = text && <div className={styles.alertText}>
        <span dangerouslySetInnerHTML={{__html: text, sanitize: true}} /> &nbsp;
        {textButton && <span className={styles.alertTextButton} onClick={textButton.onClick}>{textButton.text}</span>}
    </div>;

    const buttonsElement = buttons.length > 0 && <div className={styles.alertButtons}>
        { buttons.map((button, index) => {
                return <div className={styles.alertButton} key={index}>{getButton(button, theme)}</div>;
        })}
    </div>;

    const inputElement = inputField && <div className={styles.alertInputFieldWrapper}>
        <div className={styles.alertText}>{inputField.text}</div>
        <div className={styles.alertInputFieldContainer}>
            <input type="text" className={styles.alertInputField} placeholder={"E-Mail"} onChange={inputField.onInputChange} value={inputField.inputText} />
            <VXButton color={ inputField.isButtonDisabled ? COLOR_TYPE_DISABLED : COLOR_TYPE_CALL_TO_ACTION} staticMode={true} line1={[new VXButtonLineConfig(inputField.buttonText)]} onClick={inputField.onButtonClick} disabled={inputField.isButtonDisabled} />
        </div>
    </div>;

    return <div className={getWrapperClasses(props)} role="alert">
        <div className={styles.alert + (inputField ? (" " + styles.alertInput) : "") + (!headline ? (" " + styles.alertCenter) : "")}>
            {showIcon && <i className={getIconClasses(type, headline, text)}></i>}
            {!inputField && <div className={styles.alertTextWrapper}>
                {headlineElement}
                {textElement}
            </div>}
            { inputField && headlineElement }
            { inputField && textElement}
            { buttonsElement }
            { inputElement }
            {typeof onClose === 'function' && <i className={styles.alertClose + " icon -icon-close-full"} onClick={onClose}></i>}
        </div>
    </div>;
};

Alert.propTypes = {
    type:       PropTypes.oneOf(Object.values(TYPE)),
    theme:      PropTypes.oneOf(Object.values(THEME)),
    position:   PropTypes.oneOf(Object.values(POSITION)),
    headline:   PropTypes.string,
    text:       PropTypes.string,
    textButton: PropTypes.object,
    onClose:    PropTypes.func,
    showIcon:   PropTypes.bool,
    useTimer:   PropTypes.bool,
    inputField:  PropTypes.object,
    timer:      PropTypes.number,
    buttons:    PropTypes.arrayOf(PropTypes.shape({
        type:    PropTypes.oneOf(Object.values(BUTTON)),
        text:    PropTypes.string,
        onClick: PropTypes.func,
    })),
};

Alert.defaultProps = {
    type:     TYPE.INFO,
    theme:    THEME.DARK,
    position: POSITION.RELATIVE,
    headline: '',
    text:     '',
    textButton: null,
    onClose:  null,
    showIcon: true,
    buttons:  [],
    useTimer: false,
    timer:    5000,
    inputField: false,
};

export default Alert;

export {
    TYPE,
    THEME,
    POSITION,
    BUTTON,
};
