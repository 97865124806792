import { TYPE } from "../Alert/Alert";

const BARCOLORS = {
    BARCOLOR_GREY:  'grey',
    BARCOLOR_WHITE: 'white',
    BARCOLOR_BLUE:  'blue',
    BARCOLOR_GREEN: 'green',
    BARCOLOR_RED:   'red',
};

const getTypeByColor = (barColor) => {
    switch (barColor) {
        case BARCOLORS.BARCOLOR_GREEN:
            return TYPE.SUCCESS;
        case BARCOLORS.BARCOLOR_RED:
            return TYPE.ERROR;
        case BARCOLORS.BARCOLOR_BLUE:
        default:
            return TYPE.INFO;
    }

};

export default BARCOLORS;
export { getTypeByColor };
